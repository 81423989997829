<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                companyprice_etc {{perf}}
                <DataTable :value="companypriceetcs" class="p-datatable-sm" responsiveLayout="scroll" showGridlines stripedRows
                :lazy="true" :paginator="true" :rows="10" ref="dt" dataKey="id"
                :totalRecords="totalRecords" :loading="loading" @page="onPage($event)" @sort="onSort($event)"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[10,25,50,75,100]"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                selectionMode="single"
                v-model:filters="filters" @filter="onFilter($event)" filterDisplay="row">
                    <Column field="id" header="No" :sortable="true"></Column>
                    <Column field="price_date" header="日付" filterField="price_date" filterMatchMode="contains" ref="price_date" :sortable="true" :style="{'min-width': '10rem'}">
                        <template #filter="{filterModel,filterCallback}">
                            <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="日付" />
                        </template>
                    </Column>                    
                    <Column field="price_timestamp" header="時刻" :sortable="true"></Column>
                    <Column field="meigara_cd" header="銘柄コード" filterField="meigara_cd" filterMatchMode="contains" ref="meigara_cd" :sortable="true" :style="{'min-width': '10rem'}">
                        <template #filter="{filterModel,filterCallback}">
                            <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="コード" />
                        </template>
                    </Column>
                    <Column field="meigara_name" header="銘柄名" :sortable="true"></Column>
                    <Column field="market_name" header="市場区分" :sortable="true"></Column>
                    <Column field="ago_today_ratio" header="前日比%" :sortable="true">
                      <template #body="slotProps">
                        <div :class="atratio_priceClass(slotProps.data)">
                          {{slotProps.data.ago_today_ratio}}
                        </div>
                      </template>
                    </Column>
                    <Column field="atratio_class" header="前日比%クラス" :sortable="true"></Column>
                    <Column field="rela_at_ratio" header="比較銘柄前日比%" :sortable="true">
                      <template #body="slotProps">
                        <div :class="relaatratio_priceClass(slotProps.data)">
                          {{slotProps.data.rela_at_ratio}}
                        </div>
                      </template>
                    </Column>
                    <Column field="rstrength" header="比較銘柄相対比%" :sortable="true">
                      <template #body="slotProps">
                        <div :class="rstrength_priceClass(slotProps.data)">
                          {{slotProps.data.rstrength}}
                        </div>
                      </template>
                    </Column>                                     
                    <Column field="rstrength_class" header="比較銘柄相対比%クラス" :sortable="true"></Column>
                    <Column field="rela_meigara_cd_list" header="比較銘柄コードリスト" :sortable="true"></Column>    
                    <Column field="rela_meigara_name_list" header="比較銘柄リスト" :sortable="true"></Column>
                    <Column field="market_at_ratio" header="市場前日比%" :sortable="true">
                      <template #body="slotProps">
                        <div :class="marketatratio_priceClass(slotProps.data)">
                          {{slotProps.data.market_at_ratio}}
                        </div>
                      </template>
                    </Column>                      
                    <Column field="mrstrength" header="市場相対比%" :sortable="true">
                      <template #body="slotProps">
                        <div :class="mrstrength_priceClass(slotProps.data)">
                          {{slotProps.data.mrstrength}}
                        </div>
                      </template>
                    </Column>
                    <Column field="mrstrength_class" header="市場前日比%" :sortable="true"></Column>
                    <Column field="per" header="PER" :sortable="true"></Column>
                    <Column field="per_class" header="PERクラス" :sortable="true"></Column>
                    <Column field="pbr" header="PBR" :sortable="true"></Column>
                    <Column field="pbr_class" header="PBRクラス" :sortable="true"></Column>   
                    <Column field="yield" header="利回り" :sortable="true"></Column>
                    <Column field="yield_class" header="利回りクラス" :sortable="true"></Column>     
                    <Column field="margin_ratio" header="信用倍率" :sortable="true"></Column>
                    <Column field="margin_ratio_class" header="信用倍率クラス" :sortable="true"></Column>          
                    <Column field="market_value_str" header="時価総額str" :sortable="true"></Column>
                    <Column field="market_value" header="時価総額" :sortable="true"></Column>  
                    <Column field="market_value_class" header="時価総額クラス" :sortable="true"></Column>     
                    <Column field="settlement_date" header="決算発表日" :sortable="true"></Column>  
                    <Column field="settlement_class" header="決算発表日クラス" :sortable="true"></Column>       
                    <Column field="taishaku_kubun" header="貸借区分" :sortable="true"></Column>   
                    <Column field="volume_ratio" header="出来高前日比%" :sortable="true"></Column>
                    <Column field="vratio_class" header="出来高前日比%クラス" :sortable="true"></Column> 
                    <Column field="cont_class" header="株価連続性クラス" :sortable="true"></Column>    
                    <Column field="ma5" header="5日移動平均" :sortable="true"></Column> 
                    <Column field="ma5_dratio" header="5日移動平均乖離率" :sortable="true">
                      <template #body="slotProps">
                        <div :class="ma5dratio_priceClass(slotProps.data)">
                          {{slotProps.data.ma5_dratio}}
                        </div>
                      </template>
                    </Column>
                    <Column field="ma5_dratio_class" header="5日移動平均乖離率クラス" :sortable="true"></Column>     
                    <Column field="ma10" header="10日移動平均" :sortable="true"></Column>                   
                    <Column field="ma10_dratio" header="10日移動平均乖離率" :sortable="true">
                      <template #body="slotProps">
                        <div :class="ma10dratio_priceClass(slotProps.data)">
                          {{slotProps.data.ma10_dratio}}
                        </div>
                      </template>
                    </Column>                     
                    <Column field="ma10_dratio_class" header="10日移動平均乖離率クラス" :sortable="true"></Column>                                                                                                               
                    <Column field="ma25" header="25日移動平均" :sortable="true"></Column> 
                    <Column field="ma25_dratio" header="25日移動平均乖離率" :sortable="true">
                      <template #body="slotProps">
                        <div :class="ma25dratio_priceClass(slotProps.data)">
                          {{slotProps.data.ma25_dratio}}
                        </div>
                      </template>
                    </Column>
                    <Column field="ma25_dratio_class" header="25日移動平均乖離率クラス" :sortable="true"></Column> 
                    <Column field="ma50" header="50日移動平均" :sortable="true"></Column> 
                    <Column field="ma50_dratio" header="50日移動平均乖離率" :sortable="true">
                      <template #body="slotProps">
                        <div :class="ma50dratio_priceClass(slotProps.data)">
                          {{slotProps.data.ma50_dratio}}
                        </div>
                      </template>
                    </Column>                    
                    <Column field="ma50_dratio_class" header="50日移動平均乖離率クラス" :sortable="true"></Column> 
                    <Column field="ma75" header="75日移動平均" :sortable="true"></Column> 
                    <Column field="ma75_dratio" header="75日移動平均乖離率" :sortable="true">
                      <template #body="slotProps">
                        <div :class="ma75dratio_priceClass(slotProps.data)">
                          {{slotProps.data.ma75_dratio}}
                        </div>
                      </template>
                    </Column>                     
                    <Column field="ma75_dratio_class" header="75日移動平均乖離率クラス" :sortable="true"></Column>               
                    <Column field="gap_ratio" header="GUGD率" :sortable="true">
                      <template #body="slotProps">
                        <div :class="gapratio_priceClass(slotProps.data)">
                          {{slotProps.data.gap_ratio}}
                        </div>
                      </template>
                    </Column>                    
                    <Column field="gap_ratio_class" header="GUGD率クラス" :sortable="true"></Column> 
                    <Column field="vola_ratio" header="ボラ率" :sortable="true">
                      <template #body="slotProps">
                        <div :class="volaratio_priceClass(slotProps.data)">
                          {{slotProps.data.vola_ratio}}
                        </div>
                      </template>
                    </Column>                      
                    <Column field="vola_ratio_class" header="ボラ率クラス" :sortable="true"></Column>                     
                </DataTable>
            </div>
        </div>
    </div>
</template>

<script>
import CompanypriceetcService from '@/service/CompanypriceetcService';

export default {
    data() {
        return {
            loading: false,
            totalRecords: 0,
            companypriceetcs: null,
            lazyParams: {},
            filters1: {},
            filters: {
                'price_date': {value: '', matchMode: 'contains'},
                'meigara_cd': {value: '', matchMode: 'contains'},
            },
            perf: null,
        }
    },
    companypriceetcService: null,
    created() {
        this.companypriceetcService = new CompanypriceetcService();
    },
    mounted() {
        this.loading = true;

        this.lazyParams = {
            first: 0,
            rows: this.$refs.dt.rows,
            sortField: "id",
            sortOrder: "-1",
            filters: this.filters
        };

        this.loadLazyData();
    },
    methods: {
        loadLazyData() {
            this.loading = true;

            setTimeout(() => {
                this.companypriceetcService.getAll(
                    {dt_params: JSON.stringify( this.lazyParams )})
                        .then(data => {
                            this.companypriceetcs = data.payload.data;
                            this.totalRecords = data.payload.total;
                            this.loading = false;
                            this.perf = data.perf;
                    }
                );
            }, Math.random() * 1000 + 250);
        },
        onPage(event) {
            this.lazyParams = event;
            this.loadLazyData();
        },
        onSort(event) {
            this.lazyParams = event;
            this.loadLazyData();
        },
        onFilter() {
            this.lazyParams.filters = this.filters;
            this.loadLazyData();
        },
        atratio_priceClass(data) {
          return [
            {
              'positive': data.ago_today_ratio > 0,
              'negative': data.ago_today_ratio < 0,
              'neutral': data.ago_today_ratio == 0,
            }
          ];
        },
        relaatratio_priceClass(data) {
          return [
            {
              'positive': data.rela_at_ratio > 0,
              'negative': data.rela_at_ratio < 0,
              'neutral': data.rela_at_ratio == 0,
            }
          ];
        },
        rstrength_priceClass(data) {
          return [
            {
              'positive': data.rstrength > 0,
              'negative': data.rstrength < 0,
              'neutral': data.rstrength == 0,
            }
          ];
        },  
        marketatratio_priceClass(data) {
          return [
            {
              'positive': data.market_at_ratio > 0,
              'negative': data.market_at_ratio < 0,
              'neutral': data.market_at_ratio == 0,
            }
          ];
        },
        mrstrength_priceClass(data) {
          return [
            {
              'positive': data.mrstrength > 0,
              'negative': data.mrstrength < 0,
              'neutral': data.mrstrength == 0,
            }
          ];
        },
        ma5dratio_priceClass(data) {
          return [
            {
              'positive': data.ma5_dratio > 0,
              'negative': data.ma5_dratio < 0,
              'neutral': data.ma5_dratio == 0,
            }
          ];
        },
        ma10dratio_priceClass(data) {
          return [
            {
              'positive': data.ma10_dratio > 0,
              'negative': data.ma10_dratio < 0,
              'neutral': data.ma10_dratio == 0,
            }
          ];
        },
        ma25dratio_priceClass(data) {
          return [
            {
              'positive': data.ma25_dratio > 0,
              'negative': data.ma25_dratio < 0,
              'neutral': data.ma25_dratio == 0,
            }
          ];
        },
        ma50dratio_priceClass(data) {
          return [
            {
              'positive': data.ma50_dratio > 0,
              'negative': data.ma50_dratio < 0,
              'neutral': data.ma50_dratio == 0,
            }
          ];
        },
        ma75dratio_priceClass(data) {
          return [
            {
              'positive': data.ma75_dratio > 0,
              'negative': data.ma75_dratio < 0,
              'neutral': data.ma75_dratio == 0,
            }
          ];
        },
        gapratio_priceClass(data) {
          return [
            {
              'positive': data.gap_ratio > 0,
              'negative': data.gap_ratio < 0,
              'neutral': data.gap_ratio == 0,
            }
          ];
        },
        volaratio_priceClass(data) {
          return [
            {
              'positive': data.vola_ratio > 0,
              'negative': data.vola_ratio < 0,
              'neutral': data.vola_ratio == 0,
            }
          ];
        }
    }
}
</script>

<style scoped lang="scss">
@import '../assets/prod/styles/prices.scss';
</style>